import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to={`/`}>
      <h1 className="font-excalifont w-full flex my-4 pl-4 text-3xl text-center whitespace-nowrap">
        Andika Online
      </h1>
    </Link>
  );
};

export default Logo;
