import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Dot, TrashIcon } from 'lucide-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ListItem = ({
  id,
  title,
  isSelected,
  isUnsynced,
  archivePage,
  onSelect,
}: {
  id: string;
  title: string;
  isSelected: boolean;
  isUnsynced: boolean;
  archivePage: (id: string) => Promise<void>;
  onSelect?: () => void;
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/page/${id}`);
    onSelect?.();
  };

  const onArchive = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await archivePage(id);
      if (isSelected) {
        navigate(`/`);
      }
    },
    [id, isSelected, archivePage, navigate]
  );

  return (
    <div className="w-full group">
      <div
        onClick={onClick}
        className={cn(
          'flex items-center rounded hover:bg-muted cursor-pointer h-9 p-1',
          isSelected ? 'bg-muted' : ''
        )}
      >
        <div className="flex flex-1 items-center flex-grow overflow-hidden">
          <span className="mr-1 text-[14px]">📄</span>
          <span className="text-[14px] truncate">{title}</span>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="h-[calc(100%-0.5px)] w-8"
          onClick={onArchive}
        >
          {isUnsynced && (
            <Dot className="block group-hover:hidden h-6 w-6 text-secondary-foreground" />
          )}
          <TrashIcon className="hidden group-hover:block transition-opacity h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
